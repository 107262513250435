import React from "react";
import Modal from "react-bootstrap/Modal";
import Button from "react-bootstrap/Button";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";

function ScannedItemDetail(props) {  
  console.log(props)
  const handleClose = () => props.handleOnClose(false);

  return (
    <Modal
      show={props.show}
      onHide={handleClose}
      dialogClassName="modal-lg"
      aria-labelledby="example-custom-modal-styling-title"
    >
      <Modal.Header closeButton>
        <Modal.Title id="example-custom-modal-styling-title">
          Detail
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Row>
          <Col sm={12}>
            <div className="p-3 bg-light shadow-sm mb-3">
              <h6>Driver ID</h6>
              <p className="m-0">{props.data.driverId}</p>
            </div>
          </Col>
          <Col sm={12}>
            <div className="p-3 bg-light shadow-sm mb-3">
              <h6>Driver name</h6>
              <p className="m-0">{props.data.driverName}</p>
            </div>
          </Col>
          <Col sm={6}>
            <div className="p-3 bg-light shadow-sm mb-3">
              <h6>Barcode</h6>
              <p className="m-0">{props.data.barCode}</p>
            </div>
          </Col>
          <Col sm={6}>
            <div className="p-3 bg-light shadow-sm mb-3">
              <h6>Vehicle Number</h6>
              <p className="m-0">{props.data.vehicleNumber}</p>
            </div>
          </Col>
          <Col sm={6}>
            <div className="p-3 bg-light shadow-sm mb-3">
              <h6>Type</h6>
              <p className="m-0">

               {props.data.type === 'load' && <span className="badge badge-primary">Load</span>}
                {props.data.type === 'unload' && <span className="badge badge-warning">Unload</span>}
              </p>
            </div>
          </Col>
          <Col sm={6}>
            <div className="p-3 bg-light shadow-sm mb-3">
              <h6>Damaged</h6>
              <p className="m-0">
                {props.data.damageProduct && <span className="badge badge-danger">Yes</span>}                
                {!props.data.damageProduct && <span className="badge badge-dark">No</span> }
              </p>
            </div>
          </Col>
          <Col sm={12}>
            <div className="p-3 bg-light shadow-sm mb-3">
              <h6>Comments</h6>
              <p className="m-0">
                {props.data?.damageProduct?.remarks}
              </p>
            </div>
          </Col>
          <Col sm={12}>
            <div className="p-3 bg-light shadow-sm mb-3">
              <h6>Image</h6>
              {
                props.data?.damageProduct?.images !== null && 
                props.data?.damageProduct?.images?.url && 
                <Image                
                className="img-fluid mt-2 rounded"
                src={props.data?.damageProduct?.images?.url}
              ></Image>
              }
              
            </div>
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={handleClose}>
          Close
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

export default ScannedItemDetail;
