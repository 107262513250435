import React, { useState, useEffect } from "react";
import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Table from "react-bootstrap/Table";
import Image from "react-bootstrap/Image";
import ReactPaginate from "react-paginate";
import API_REQUESTS from "../config/config";
import API_INSTANCE from "../connection/index";
import ScannedItem from "../components/ScannedItem";
import ScannedItemDetail from "../components/ScannedItemDetail";

import "./ScannedData.scss";

function ScannedData(props) {
  const [pageNumber, setPageNumber] = useState(1);
  const [scannedData, setScannedData] = useState([]);
  const [modalShow, setModalShow] = useState(false);
  const [modalData, setModalData] = useState({});

  useEffect(() => {
    fetchScannedData(1);
  }, []);

  const fetchScannedData = async (pageNumber) => {
    const params = {
      company_id: props.companyInfo.company_id,
      page: pageNumber,
    };
    setPageNumber(pageNumber);
    await API_INSTANCE.get(API_REQUESTS.COMPANY_SCANNED_DATA, {
      params: params,
    }).then((resp) => {
      // console.log(resp.data);
      if (resp.data) {
        setScannedData(resp.data);
      }
    });
  };

  const handlePageChange = async (data) => {
    console.log("I'm changed.....");

    if (data.selected === 0) {
      fetchScannedData(1);
    } else {
      fetchScannedData(data.selected + 1);
    }
  };

  const handleItemClick = (item) => {
    const cur_item = scannedData.data.filter((obj) => obj.id === item);    
    setModalData(cur_item[0]);
    setModalShow(true);
  };

  const onModalCloseEvent = () => {
    setModalShow(false);
    setModalData({});
  };

  return (
    <div>
      {/* <header>
        <Navbar
          collapseOnSelect
          bg="light"
          expand="lg"
          className="fixed-top shadow-sm"
        >
          <Navbar.Brand href="#">
            <Image fluid src="https://www.mapon.com/images/logo.svg" />
          </Navbar.Brand>
          <Nav.Link href="#" className="text-dark ml-auto">
            {props.companyInfo.name}
          </Nav.Link>
        </Navbar>
      </header> */}
      <main className="main">
        <Container fluid className="p-0">
          <Row className="m-0">
            <Col>
              <section className="py-3">
                <h2 className="mb-3">Scanned Data</h2>
                <div className="table-responsive">
                  <Table striped bordered hover variant="light">
                    <thead>
                      <tr>
                        <th>#</th>
                        <th>Barcode #</th>
                        <th>Vehicle #</th>
                        <th>Driver Id</th>
                        <th>Driver Name</th>
                        <th>Type</th>
                        <th style={{whiteSpace: 'nowrap'}} className="image-box">Damaged goods</th>
                        <th className="image-box">Comments</th>
                        <th style={{textAlign: 'center'}} className="image-box">Created</th>
                        <th className="action">Actions</th>
                      </tr>
                    </thead>
                    <tbody>
                      {scannedData?.data?.map((item, index) => {
                        return (
                          <ScannedItem
                            key={item.id}
                            {...item}
                            onItemClick={handleItemClick}
                          />
                        );
                      })}
                      {scannedData?.data?.length === 0 && (
                        <tr>
                          <td colSpan="10">
                            {" "}
                            No Record available in database yet.
                          </td>
                        </tr>
                      )}
                    </tbody>
                  </Table>
                </div>
                {/* <div className="pagination-box d-flex justify-content-center mt-3"> */}
                {scannedData?.data?.length > 0 && (
                  <ReactPaginate
                    previousLabel="Previous"
                    nextLabel="Next"
                    breakLabel={"..."}
                    breakClassName={"break-me"}
                    containerClassName="pagination"
                    activeClassName={"active"}
                    pageCount={scannedData?.meta?.last_page}
                    marginPagesDisplayed={2}
                    pageRangeDisplayed={2}
                    onPageChange={handlePageChange}
                    initialPage={0}
                    disableInitialCallback={true}
                    forcePage={scannedData?.meta?.current_page - 1}
                  />
                )}
                {/* </div> */}
              </section>
            </Col>
          </Row>
          <ScannedItemDetail
            show={modalShow}
            data={modalData}
            handleOnClose={onModalCloseEvent}
          />
        </Container>
      </main>
    </div>
  );
}

export default ScannedData;
