import React, { useEffect, useState } from "react";

import {
  BrowserRouter as Router,
  Redirect,
  useLocation,
  useHistory,
} from "react-router-dom";
import API_REQUESTS from "../config/config";
import API_INSTANCE from "../connection/index";
import ScannedData from "./ScannedData";

function Home() {
  let query = useQuery();
  let history = useHistory();
  // const [apiKey] = useState(query.get("apikey"));
  const [user_token] = useState(query.get("user_token"));
  const [isLoading, setIsLoading] = useState(false);
  const [companyInfo, setCompanyInfo] = useState({});

  // on component mount check api key is valid then get company info from mapon and
  useEffect(() => {
    if (user_token !== null) {
      setIsLoading(true);
      const getCompanyInfo = async (user_token) => {
        await API_INSTANCE.post(API_REQUESTS.VERIFY_USER, { 'user_token': user_token })
          .then((resp) => resp.data)
          .then((companyObj) => {
            console.log(companyObj)
            if (companyObj.error && companyObj.error.code === 3) {
              history.replace("/403");
            } else {
              setCompanyInfo(companyObj.data.users[0]);
            }
          })
          .catch((error) => {
            console.log(error.code);
            setIsLoading(false);
          });
      };
      getCompanyInfo(user_token);
    }
  }, []);

  useEffect(() => {
    console.log(companyInfo);
    if (companyInfo?.id > 0) {
      setIsLoading(false);
    }
  }, [companyInfo]);

  if (user_token === null) {
    return <Redirect to="/403" />;
  }

  if (!isLoading && companyInfo?.id > 0) {
    return <ScannedData companyInfo={companyInfo} />;
  }

  return <div>Please Wait......</div>;
}

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  return new URLSearchParams(useLocation().search);
}
export default Home;
